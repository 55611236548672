<template>
    <Form ref="form" v-slot="{values, meta, errors, resetForm, validate}">
        <slot :values="values" :meta="meta" :errors="errors" :valid="meta.valid && meta.dirty" :resetForm="resetForm" :validate="validate"></slot>
    </Form>
</template>

<script>
import {Form} from 'vee-validate';

export default {
    name: "CForm",
    components:{Form},
    computed: {
        reset() {
            return this.$refs?.form.resetForm;
        },
    },
    methods: {
        validateField(p) {
            return this.$refs?.form.validateField(p);
        },
        getValues() {
            return this.$refs?.form.getValues();
        },
        getErrors() {
            return this.$refs?.form.getErrors();
        }
    },
}
</script>

<style scoped lang="scss">

</style>
